import { Image } from 'react-bootstrap';
import { Link } from "react-router-dom";
import GetImageUrl from '../throughSiteComponents/GetImageUrl';

export default function PhotoDiv(props){
  return (
    <Link className="col-lg-3 col-md-4 col-sm-6 photoLink" to={`/theArtist/${props.artist}`}>
      <div className="galleryPhotoDiv">
        <div className="galleryPhotoReference">
          <Image className="galleryAbsolutePhoto skeleton" src={GetImageUrl(props.src)} />
        </div>
      </div>
    </Link>
  );
}