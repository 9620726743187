import { Link } from "react-router-dom";
import { Button } from 'react-bootstrap';
import GetImageUrls from '../throughSiteComponents/GetImageUrls';

export default function BigPhotosWithPageDirections(props) {
  const image = GetImageUrls(props.src, "img-fluid skeleton", props.alt, {order: props.rightSide ? 1 : 0  })
  return (
    <>
    { image && (
    <div className={props.class}>
      <div className="bigPhotosWithPageDirections">
        {image}
        <div>
          <div>
            <p className="text">{props.text}</p>
            <Link to={props.navigateTo}>
              <Button variant="dark" size="lg">
                {props.buttonName}
              </Button>
            </Link>
          </div>
        </div>
      </div>
    </div>
    )}
    </>
  );
}