import Filter from "./Filter";

export default function FiltersDiv(){
   return (
     <div className="filtersDiv">
       <Filter
         id="CityFilter"
         options={[['1','all','ŞEHİR'],['2','category1','İstanbul'],['3','category2','Muğla']]}
       />
       <Filter
         id="LocationFilter"
         options={[['1','all','SEMT'],['2','category1','Kadıköy'],['3','category2','Fethiye']]}
       />
       <Filter
         id="KindFilter"
         options={[['1','all','TARZ']]}
       />
     </div>
   );
}