import { useState, useEffect } from 'react';
import { Container, Form, Button } from 'react-bootstrap';
import { doc, collection, setDoc, getDocs } from "firebase/firestore";
import { db } from "../config/tattooAppConfigure";
import GetImageUrls from '../throughSiteComponents/GetImageUrls';

export default function IconPhoto(){
  return (
    <>
     {GetImageUrls("/essentialPhotos/chatbotIcon.png", "iconPhoto", "Chatbox İşareti")}
    </>
  );
}

function Chatbot() {
  const [showChat, setShowChat] = useState(false);
  const [messages, setMessages]=useState(null);
  const [loading, setLoading]=useState(false);

  const [currentDate, setCurrentDate] = useState(null);

  const warning = "Mesajınız içerik kapsamımı aştığından bir yanıt veremem."

  useEffect(() => {
    const today = new Date();
    const month = today.getMonth() + 1;
    const year = today.getFullYear();
    const date = today.getDate();
    const random = Math.random(1, 100);
    const formattedDate = today.toLocaleTimeString();
    setCurrentDate(`${month}:${date}:${year}:${formattedDate}:${random}`);
  }, []);

  const getMessages = async () => {
    const querySnapshot = await getDocs(collection(db, "generate", "conversations", currentDate));
    const chats = querySnapshot.docs.map(doc =>({id: doc.id, prompt:doc.data().prompt, response:doc.data().response}));
    setMessages(chats);
  }

  const handleSubmit = async(event) => {
    event.preventDefault();

    const prompt = event.target.elements.message.value;
    event.target.elements.message.value = '';

    try{
      const today = new Date();
      const messageTime = today.toLocaleTimeString();
      setDoc(doc(db, "generate", "conversations", currentDate, messageTime ), {prompt});
      setLoading(true);
      setTimeout(() => {
        getMessages();;
        setLoading(false);
      }, 10000);
    } catch (error){
      console.log(error);
    }
  }

  function createChat(message){
    return (
      <div key={message.id}>
        <div className="chatbotChatFirstDiv">
          <p>{message.prompt}</p>
        </div>
        <div className="chatbotChatSecondDiv">
          <p>{message.response ? message.response : warning}</p>
        </div>
      </div>
    );
  }

  return (
    <Container className="chatbotContainer ">

      {showChat && (
        <div className="chatbotDiv">
          <div className="chatbotDivFirstChild">
            <p className="chatbotBrand" ><strong>TATTOO-IST</strong></p>
          </div>
          <div className="chatbotDivSecondChild">
            <div className="chatbotSlideFirst">
              <div className="chatbotSlideSecond">
                {messages && messages.map(createChat)}
                {loading && <p>cevaplanıyor...</p>}
              </div>
            </div>
          </div>
          <div className="chatbotDivThirdChild">
            <Form onSubmit={handleSubmit}>
              <Form.Group className="chatbotForm" controlId="formBasicPassword">
                <Form.Control className="chatbotFormInput" size="lg" name="message" placeholder="Aklınıza Takılanlar" />
                <Button className="chatbotFormButton" variant="link" type="submit">
                </Button>
              </Form.Group>
            </Form>
          </div>
        </div>
      )}

      <div className="chatbotIconDiv">
        <Button variant="link" onClick={e=>{setShowChat(!showChat)}}>
          <IconPhoto />
        < /Button>
      </div>
    </Container>
  );
}