import { Image } from 'react-bootstrap';
import GetImageUrl from '../../throughSiteComponents/GetImageUrl';

export default function OverlayCard(props){
  return (
    <div className="overlay">
      <div className="overlayFirstChild">
        <div className="overlaySecondChild">
          <div className="row">
            <Image className="col-6 overlayImg skeleton" src={GetImageUrl(`/artists/kadikoy/${props.artist}/theArtistPhoto01.jpeg`)} />
            <Image className="col-6 overlayImg skeleton" src={GetImageUrl(`/artists/kadikoy/${props.artist}/theArtistPhoto02.jpeg`)} />
            <Image className="col-6 overlayImg skeleton" src={GetImageUrl(`/artists/kadikoy/${props.artist}/theArtistPhoto03.jpeg`)} />
            <Image className="col-6 overlayImg skeleton" src={GetImageUrl(`/artists/kadikoy/${props.artist}/theArtistPhoto04.jpeg`)} />
            <Image className="col-6 overlayImg skeleton" src={GetImageUrl(`/artists/kadikoy/${props.artist}/theArtistPhoto05.jpeg`)} />
            <Image className="col-6 overlayImg skeleton" src={GetImageUrl(`/artists/kadikoy/${props.artist}/theArtistPhoto06.jpeg`)} />
          </div>
        </div>
      </div>
    </div>
  );
}