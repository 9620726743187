import { useState } from 'react';
import { Carousel } from 'react-bootstrap';
import GetImageUrls from '../throughSiteComponents/GetImageUrls';

export default function MyCarousel(){
  const [index, setIndex] = useState(0);

  const handleSelect = (selectedIndex) => {
    setIndex(selectedIndex);
  };

  const image1=GetImageUrls("/essentialPhotos/carousel1.jpg", "img-fluid skeleton", "Birinci slayt")
  const image2=GetImageUrls("/essentialPhotos/carousel2.jpg", "img-fluid skeleton", "İkinci slayt")
  const image3=GetImageUrls("/essentialPhotos/carousel3.jpg", "img-fluid skeleton", "Üçüncü slayt")

  return (
    <div className="myCarouselDiv">
    { image1 && image2 && image3 && (
      <>
      <h1> Biz Kimiz? </h1 >
      <Carousel activeIndex={index} onSelect={handleSelect}>
        <Carousel.Item className="carouselImage">
            {image1}
          <Carousel.Caption>
            <p className="carouselsText">On binlerce dövme sanatçısı arasından en doğru seçimi yapmak oldukça zorlayıcı olabilir. </p>
          </Carousel.Caption>
        </Carousel.Item >
        <Carousel.Item className="carouselImage">
          {image2}
          <Carousel.Caption>
            <p className="carouselsText" >Bu süreci kolaylaştırmak için İstanbulun en yetenekli sanatçılarıyla sizleri bir araya getiriyoruz.</p>
          </Carousel.Caption>
        </Carousel.Item>
        <Carousel.Item className="carouselImage">
          {image3}
          <Carousel.Caption>
            <p className="carouselsText" >Normal koşullarda sosyal medyanın karmaşasında fark edemeyeceğiniz yetenekler sade bir ortamda, ön ödeme olmaksızın sizlerle. </p>
          </Carousel.Caption>
        </Carousel.Item>
      </Carousel>
      </>
      )}
    </div>
  );
}