import React, {useRef, useState,} from "react";
import "../styles/SignIn.css";
import {Link} from "react-router-dom"
import { Form, Button, Card, Alert } from 'react-bootstrap';
import { useAuth } from "../contexts/AuthContext";

function ForgotPassword(){
  const emailRef = useRef();
  const { resetPassword } = useAuth();
  const [error, setError] = useState("");
  const [message, setMessage] = useState("");
  const [loading, setLoading] = useState(false);

  async function handleSubmit(e){
    e.preventDefault()

    try{
      setMessage("")
      setError("")
      setLoading(true)
      await resetPassword(emailRef.current.value)
      setMessage("Talimatlar İçin Gelen Kutunuzu Kontrol Edin")
    }catch{
      setError("Şifre Yenileme Esnasında Bir Hataylar Karşılaşıldı")
    }

    setLoading(false)
  }

  return (
    <div className="signInWrapper" >
      <Card className="signInCard">
        <Card.Body>
          <h2> ŞİFRENİZİ DEĞİŞTİRİN </h2>
          {error && <Alert variant="danger">{error}</Alert>}
          {message && <Alert variant="success">{message}</Alert>}
          <Form onSubmit={handleSubmit}>
            <Form.Group className="mb-3" controlId="formBasicEmail">
              <Form.Label>Email Adresiniz</Form.Label>
              <Form.Control type="email" ref={emailRef} required/>
            </Form.Group>
            <Button disabled={loading} variant="dark" type="submit">
              Şifreyi Yenile
            </Button>
          </Form>
        </Card.Body>
      </Card>
      <div>
        <Link to="/signin"> Giriş Yapın </Link>
      </div>
    </div>
  );
}

export default ForgotPassword;