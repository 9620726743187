import {useState} from "react";
import { collection, addDoc } from "firebase/firestore";
import { db } from "../config/tattooAppConfigure";
import  Footer from "../throughSiteComponents/Footer";
import { Form, Button, Image } from 'react-bootstrap';
import "../styles/AreYouAnArtist.css";
import GetImageUrl from '../throughSiteComponents/GetImageUrl';

function AreYouAnArtist(){

  const options = [
    { value: 'Kadıköy'},
  ];

  const [selectedLocationValue, setLocationValue] = useState('');
  const [submitted, setSubmitted] = useState(false);

  const handleChange = (event) => {
    setLocationValue(event.target.value);
  };

  const handleSubmit = async(event) => {
    event.preventDefault();
    setSubmitted(true);

    const name =  event.target.elements.name.value;
    const phone = event.target.elements.phone.value;
    const email = event.target.elements.email.value;
    const notes = event.target.elements.notes.value;
    const selectedOption = selectedLocationValue;

    const body ={
      name,
      phone,
      email,
      notes,
      selectedOption
    };

    try{
      await addDoc(collection(db, "wantsToWorkWith"), {
        body
      });
    } catch (error){
      console.log(error);
    }
  };

  return (
    <>
    <div className="pageWrapper">
      <div className="newArtistPhotoDiv skeleton">
        <Image className="newArtistPhoto" src={GetImageUrl("/essentialPhotos/ourNewArtist.jpg")}/>
      </div>
      {!submitted ?

      <Form className="newArtistForm" onSubmit={handleSubmit}>
        <p>Kendinize has bir tarzınız olduğunu düşünüyorsanız, bizimle çalışın. </p>
        <Form.Group className="mb-3">
          <Form.Label>İsim</Form.Label>
          <Form.Control required name="name" placeholder="Kendinizin veya dükkanınızın ismi " />
        </Form.Group>

         <Form.Group className="mb-3">
          <Form.Label>Telefon</Form.Label>
          <Form.Control required name="phone" />
        </Form.Group>

        <Form.Group className="mb-3">
          <Form.Label>Email</Form.Label>
          <Form.Control type="email" required name="email"/>
        </Form.Group>

        <Form.Group controlId="formBasicSelect" className="mb-3">
          <Form.Label>Dükkan Konumu</Form.Label>
          <Form.Select value={selectedLocationValue} onChange={handleChange}>
            {options.map((option) => (
              <option key={option.value} value={option.value}>
                {option.value}
              </option>
            ))}
          </Form.Select>
        </Form.Group>

        <Form.Group className="mb-3">
          <Form.Label>Ekstra Not</Form.Label>
          <Form.Control required name="notes" placeholder="Bize söylemek istedikleriniz" />
        </Form.Group>

        <Button variant="dark" type="submit">
          Gönder
        </Button>
      </Form>
      :
      <div className="areYouAnArtistReturnText">
      <p>Yanıtınız kaydedilmiştir en kısa sürede geri dönüş sağlayacağız </p>
      </div>
      }
    </div>
    <Footer />
    </>
  );
}

export default AreYouAnArtist;