import {useState, useEffect} from "react";
import "../styles/Gallery.css";
import  PhotoDiv from "../galleryPageComponents/PhotoDiv";
import  FiltersDiv from "../galleryPageComponents/FiltersDiv";
import  Footer from "../throughSiteComponents/Footer";
import { collection, getDocs } from "firebase/firestore";
import { db } from "../config/tattooAppConfigure";

function Gallery(){
  const [photos, setPhotos]=useState();

  //Bunun sonucunu direk array olarak yazarsan photodiv de getimageurls kullanabiliyorsun ama böyle almaya çalışırsan illa renderı için getimageurl kullancan statei değiştircek
  const getArtists = async () => {
    const querySnapshot1 = await getDocs(collection(db, "galleryPhotosFirst"));
    const newArtists1 = querySnapshot1.docs.map(doc =>({id: doc.id, src:doc.data().src, studioName:doc.data().studioName}));
    setPhotos(newArtists1);
  }

  useEffect(() =>{
    getArtists();
  },[]);

  function createPhoto(photo){
    return (
      <PhotoDiv
        key={photo.id}
        src={photo.src}
        artist={photo.studioName}
      />
    );
  }

  return(
    <>
      <div className="galleryPageWrapper">
        <FiltersDiv />
        <div className="row">
          {photos && photos.map(createPhoto)}
        </div>
      </div>
      <Footer />
    </>
  );
}

export default Gallery;