import {  useState, useEffect } from 'react';
import { getDownloadURL, ref } from 'firebase/storage';
import { storage } from '../config/tattooAppConfigure';

function GetImageUrl(path){
  const [image, setImage]= useState('');

  // asenkron çalışıyo doğru bir şekilde storage dan çekiyo sıkıntı yok.
  // const ve function olarak adlandırmanın bi farkı yok aynı fonksiyon içinde kullanıcaksan const farklısındaysa function diyebilirsin.
  const fetchImage = async () => {
    const imageRef = ref(storage,path);
    const url = await getDownloadURL(imageRef);
    setImage(url);
  };

  useEffect(() => {
    fetchImage();
  }, []);

  return image;
};

export default GetImageUrl;
