import { useParams } from 'react-router-dom';
import "../styles/TheArtist.css";
import HeadSection from "../theArtistPageComponents/HeadSection";
import ContentSwitcher from "../theArtistPageComponents/ContentSwitcher";
import  Footer from "../throughSiteComponents/Footer";

function TheArtist(){

  const { artist } = useParams();

  return (
    <>
      <div className="theArtistPageWrapper">

        <HeadSection
          artist={artist}
        />
        <ContentSwitcher
          artist={artist}
        />
      </div>
    <Footer />
    </>
  );
}

export default TheArtist;