import {useState} from "react";
import { Link } from "react-router-dom";
import "../styles/NewArtistOrLogin.css";
import { Button, Card } from 'react-bootstrap';

function NewArtistOrLogin(){

  return (
    <div className="newArtistOrLoginWrapper">
      <Card className="newArtistOrLoginCard">
        <Card.Body>
          <Link to="/signin">
            <Button variant="dark">
              Giriş Yap
            </Button>
          </Link>
          <Link className="newArtistOrLoginButtonSecond" to="/ourNewArtist">
            <Button variant="dark">
              Birlikte Çalışmak İster Misiniz?
            </Button>
          </Link>
        </Card.Body>
      </Card>
    </div>
  );
}

export default NewArtistOrLogin;