import {useState}  from "react";
import { collection, addDoc } from "firebase/firestore";
import { db } from "../config/tattooAppConfigure";
import { Button, Form, Card } from 'react-bootstrap';
import GetImageUrl from '../throughSiteComponents/GetImageUrl';

function BookingDiv(props){

  const imageUrl = GetImageUrl("/essentialPhotos/ourNewArtist.jpg");

  const sizeOptions = [
    { value: 'Küçük - el ile kapatılabilicek boyutta'},
    { value: 'Orta - defter ile kapatılabilicek boyutta'},
    { value: 'Büyük'},
  ];

  const [sizeValue, setSizeValue] = useState(sizeOptions[0].value);
  const [submitted, setSubmitted] = useState(false);

  const handleChange = (event) => {
    setSizeValue(event.target.value);
  };

  const handleSubmit = async(event) => {
    event.preventDefault();
    setSubmitted(true);

    const artist = props.artist;
    const name =  event.target.elements.name.value;
    const email = event.target.elements.email.value;
    const phone = event.target.elements.phone.value;
    const date = event.target.elements.date.value
    const area = event.target.elements.area.value;
    const color = event.target.elements.color.value;
    const details = event.target.elements.details.value;
    const selectedOption = sizeValue;

    const text =JSON.stringify({
      artist,
      name,
      email,
      phone,
      date,
      area,
      color,
      details,
      selectedOption
    });

    const to = "the.egeeren.2021@gmail.com";
    const subject = "Yeni Müşterin Geldi Kerata";
    const message = {subject, text}

    try{
      await addDoc(collection(db, "customers"), {
        to, message
      });
    } catch (error){
      console.log(error);
    }
  };

  return (
    <>
    {!submitted ?
    <Form  onSubmit={handleSubmit}>

      <div className="formWrapper">
        <div className="formsDiv">
          <Form.Group className="mb-3">
            <Form.Label>İsim</Form.Label>
            <Form.Control required name="name"/>
          </Form.Group>

          <Form.Group className="mb-3">
            <Form.Label>Email Adresi</Form.Label>
            <Form.Control required name="email"/>
          </Form.Group>

          <Form.Group className="mb-3">
            <Form.Label>Telefon</Form.Label>
            <Form.Control required name="phone"/>
          </Form.Group>

          <Form.Group className="mb-3">
            <Form.Label>Tarih Terciği</Form.Label>
            <Form.Control required name="date" placeholder="Tercihinize göre en yakın boşluklar size bildirilicektir" />
          </Form.Group>
        </div>

        <div className="formsDiv">
          <Form.Group className="mb-3">
            <Form.Label>Dövme Bölgesi</Form.Label>
            <Form.Control required name="area"/>
          </Form.Group>

          <Form.Group className="mb-3">
            <Form.Label>Renk Tercihi</Form.Label>
            <Form.Control required name="color"/>
          </Form.Group>

          <Form.Group className="mb-3">
            <Form.Label>Detaylar</Form.Label>
            <Form.Control required name="details" placeholder="Dövmenizi anlatın" />
          </Form.Group>

          <Form.Group controlId="formBasicSelect">
            <Form.Label>Büyüklük</Form.Label>
            <Form.Select  value={sizeValue} onChange={handleChange}>
              {sizeOptions.map((option) => (
                <option key={option.value} value={option.value}>
                  {option.value}
                </option>
              ))}
            </Form.Select>
          </Form.Group>

        </div>
      </div>

      <Button className="bookingButton" variant="dark" type="submit">
        Gönder
      </Button>

    </Form>
    :
    <Card className="bookingCard">
      <div className="bookingCardImageDiv skeleton">
        <Card.Img src={imageUrl} />
      </div>
      <Card.Body>
        <p>
          Talebiniz kaydedilmiştir en kısa sürede dönüş sağlanacaktır.
        </p>
      </Card.Body>
    </Card>
    }
    </>
  );
}

export default BookingDiv;