import { Link } from "react-router-dom";
import { Button } from 'react-bootstrap';

export default function BottomFixedButtons(props){
   return (
     <div className="container1">
       <div className="rounded-half-height">
         <Link  to="/newArtistOrLogIn">
           <Button variant="secondary" size="lg">
             Sanatçı mısınız?
           </Button>
         </Link>
       </div>
     </div>
   );
}