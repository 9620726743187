import { Image } from 'react-bootstrap';
import "../styles/Home.css";
import  DivThatSlidesOverWrapper from "../homePageComponents/DivThatSlidesOverWrapper";
import  BottomFixedButtons from "../homePageComponents/BottomFixedButtons";
import  Chatbot from "../homePageComponents/Chatbot";
import GetImageUrl from '../throughSiteComponents/GetImageUrl';

function Home(props){
  //useMemonun çok kullanımı performans açısından da sıkıntılı sonuçlar verir, hafızada yer kapladığından.En son kullanıp kullanmıcağına karar ver.

  return (
    <div className="homeWrapper" style={{position:"relative", overflowX: "clip"}}>
        {/* imageın absolute özelliğini kaldırıp img-fluid versek olmaz mı?*/}
        <>
        <Image className="mainPhoto skeleton" src={GetImageUrl("/essentialPhotos/mainPhoto.jpg")} />
        <DivThatSlidesOverWrapper />

        <Chatbot />
        <BottomFixedButtons />
        </>
    </div>
  );
}

export default Home;