import { useState } from "react";
import { Button } from 'react-bootstrap';
import ArtistsMainDiv from "./ArtistsMainDiv";
import FiltersDiv from "./FiltersDiv";

export default function ArtistsWrapper(){
  const [forTattoos, setForTattoos]=useState(false)

  const handleClick = (value) =>{
    if (value ===forTattoos){
      setForTattoos(false);
    }
    else{
      setForTattoos(value);
    }
  };

  return (
    <div className="artistsWrapper">
      <div className="filtersAndButtons">

        <FiltersDiv />

        <div className="button-group-div">
          <div className="btn-group">
            <Button onClick={() => handleClick(false)} variant="outline-dark" size="lg" className={`${!forTattoos? 'active' : ''}`}>
              Sanatçılar
            </Button>
            <Button onClick={() => handleClick(true)} variant="outline-dark" size="lg" className={`${forTattoos? 'active' : ''}`}>
              Dövmeler
            </Button>
          </div>
        </div>
      </div>

      <ArtistsMainDiv
        activeness={forTattoos}
      />
    </div>
  );
}