import { useState, useEffect } from "react";
import Card  from "./artistsMainDivComponents/Card";
import ThemeProvider from 'react-bootstrap/ThemeProvider';
import { collection, getDocs } from "firebase/firestore";
import { db } from "../config/tattooAppConfigure";

export default function ArtistsMainDiv(props){

  const [artists, setValue]=useState();

  //Bunun sonucunu direk array olarak yazarsan photodiv de getimageurls kullanabiliyorsun ama böyle almaya çalışırsan illa renderı için getimageurl kullancan statei değiştircek
  const getArtists = async () => {
    const querySnapshot = await getDocs(collection(db, "artists"));
    const newArtists = querySnapshot.docs.map(doc =>({id: doc.id, name:doc.data().studioName}));
    setValue(newArtists);
  }

  useEffect(() =>{
    getArtists();
  }, []);

  function createCard(artist){
    return (
      <Card
        key={artist.id}
        name={artist.name}
        overlay= {props.activeness}
        location="Kadıköy / İstanbul"
      />
    );
  }


  return (
    <ThemeProvider breakpoints={['xxxl', 'xxl', 'xl', 'lg', 'md', 'sm', 'xs', 'xxs']} minBreakpoint="xxs">
      <div className="row artistsPhotosDiv">
        {artists && artists.map(createCard)}
      </div>
    </ThemeProvider>
  );
}