import { Link } from "react-router-dom";
import { Button, Image } from 'react-bootstrap';
import GetImageUrl from '../throughSiteComponents/GetImageUrl';

export default function Post(props){
  return (
    <div className="post">

      <div className="photoDiv" style={{order: props.leftSide ? 1 : 0 }}>
        <Image className={ `postPhoto skeleton ${props.className}` } src={GetImageUrl(props.src)}/>
      </div>

      <div className="textContainersDiv">
        <div className="textContainer">
          <p className="blogPostText">{props.text}</p>
          <Link to={props.navigateTo}>
            <Button variant="light">
              Paylaşıma Git
            </Button>
          </Link>
        </div>
      </div>
    </div>
  );
}