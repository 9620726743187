import Image from 'react-bootstrap/Image';
import GetImageUrl from '../throughSiteComponents/GetImageUrl';

function AfterCareOfTattoo(){

  return (
    <>
        <h1><strong>Dövme Bakımı Nasıl Yapılır?< /strong></h1>

        <h2><strong>>İlk Gün</strong></h2>
        <ul>
            <li>Dövmenizi sanatçınızın talimatlarına göre temizleyin ve kurulayın.</li>
            <li>Dövmenizi nemli tutmak için ince bir tabaka nemlendirici krem uygulayın.</li>
            <li>Kaşıntıya karşı direnin ve kaşımayın.</li>
            <li>Dövmenizin nefes almasını sağlayan bol giysiler giyin.</li>
        </ul>

        <h2><strong>İlk Hafta:</strong></h2>
        <p>Nemlendirmeye Devam Edin: Dövmenizi günde 2-3 kez nemlendirici krem ile nemlendirin.</p>

        <h3><strong>Kaşıntı Kontrolü</strong></h3>
        <p>Kaşıntı aşırıysa, soğuk kompres uygulayın veya antihistaminik krem kullanın.</p>

        <h3><strong>Rahat Giysiler</strong></h3>
        <p>Sentetik kumaşlardan kaçının ve bol giysiler giyin.</p>

        <h3><strong>Uyku Pozisyonu</strong></h3>
        <p>Dövmenize sürtünmemesi için yan yatarken uyuyun.</p>

        <h2><strong>İlk Ay</strong></h2>
        <p>Güneşten Koruma: Dövmenizi güneş kremi ile güneş ışınlarından koruyun.</p>

        <p>Suyla Sınırlı Temas: Uzun süreli su banyolarından ve yüzmekten kaçının.</p>

        <p>Takip Randevusu: Dövmenizin iyileşmesini kontrol etmek için sanatçınızla bir randevu ayarlayın.</p>

        <p>Dövmenizin bakımı, renginin canlı kalması ve enfeksiyon kapmaması için önemlidir.</p>

        <p>**Not:** Bu sadece genel bir rehberdir. Dövmenizin özel bakımı için sanatçınızın talimatlarına uyun.</p>

        <Image className="thePostPhotos" src={GetImageUrl("postPhotos/notToRegret/notToRegret3.jpg")} />
    </>
  );
}

export default AfterCareOfTattoo;