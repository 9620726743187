import { Image } from 'react-bootstrap';
import GetImageUrl from '../throughSiteComponents/GetImageUrl';

function HeadSection(props){
  return (
    <div className="headSection">
      <Image className="artistPhoto" src={GetImageUrl(`/artists/kadikoy/${props.artist}/header.jpeg`)} alt="artist photo"/>
      <div className="infoDiv">
        <h1> {props.artist}</h1>
        <p>Kadıköy / İstanbul</p>
      </div>
    </div>
  );
}

export default HeadSection;
