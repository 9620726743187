import { useState, useEffect } from "react";
import GetImageUrls from '../throughSiteComponents/GetImageUrls';

export default function SlidingImages(props){

  const [scrollDifference, setScrollDifference]=useState();
  const [leftMargin, setLeftMargin]=useState();

  const imageData = [
   { key:1, image:GetImageUrls('/essentialPhotos/slideImg1.jpg', 'cardImage skeleton', 'Photo of a woman with arm tattoo.')},
   { key:2, image:GetImageUrls('/essentialPhotos/slideImg2.jpg', 'cardImage skeleton', 'Tattooed couple holding hands.')},
   { key:3, image:GetImageUrls('/essentialPhotos/slideImg3.jpg', 'cardImage skeleton', 'Photo of a woman with neck tattoo.')},
   { key:4, image:GetImageUrls('/essentialPhotos/slideImg4.jpg', 'cardImage skeleton', 'Tattoo on the back of the foot.')},
   { key:5, image:GetImageUrls('/essentialPhotos/slideImg5.jpg', 'cardImage skeleton', 'Skull tattoo.')},
   { key:6, image:GetImageUrls('/essentialPhotos/slideImg6.jpg', 'cardImage skeleton', 'An old men with tattoos')},
   { key:7, image:GetImageUrls('/essentialPhotos/slideImg7.jpg', 'cardImage skeleton', 'Leg tattoo.')},
   { key:8, image:GetImageUrls('/essentialPhotos/slideImg8.jpg', 'cardImage skeleton', 'Feet tattoo.')},
   { key:9, image:GetImageUrls('/essentialPhotos/slideImg9.jpg', 'cardImage skeleton', 'Wrist tattoo.')}
];

  useEffect(() => {
    const handleResize = () => {
      let newValue;
      if (window.innerWidth < 500) {
        newValue = 1100;
    } else if (window.innerWidth < 1000) {
        newValue = 1700;
    } else {
        newValue = 2400;
    }
      //Bu şekilde conditionların içerisinde setleyemiyorsun, purity ile alakalı olabilir.
      setScrollDifference(newValue);
    };

    handleResize();

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    const moveHorizontally = () => {
      setLeftMargin(window.scrollY - scrollDifference);
    };

    window.addEventListener('scroll', moveHorizontally);

    return () => {
      window.removeEventListener('scroll', moveHorizontally);
    };
  }, [window.scrollY]);

  //Şu event listener koyduğumuz useEffectlerden birinin onMount olması lazım diğerinde ise kaydırma miktarına bağlı, neden?

   return (
    <>
     { imageData && (
     <div className="slidingImages">
       <div className="cardsContainer" style={{ left: `${leftMargin}px` }}>
         {imageData.map((object) => (
           <div key={object.key} className="cardSlideImages">
             {object.image}
           </div>
         ))}
       </div>
     </div>
     )}
     </>
   );
}