import {useState} from "react";
import { useLocation } from "react-router-dom";
import "./throughSiteComponentsStyle.css";
import { Container, Nav, Navbar } from 'react-bootstrap';
import GetImageUrls from '../throughSiteComponents/GetImageUrls';

function MyNavbar() {
  const [isScrolled, setIsScrolled] = useState(false);
  const location = useLocation();

  window.addEventListener('scroll', changeColor);
  function changeColor(){
    const scrollPosition = window.scrollY
    setIsScrolled(scrollPosition >= 10);
  }

  const isHomePage = location.pathname === "/";

  return (
    <Navbar  expand="md" className={isScrolled ? 'scrolledNavbar bg-body-tertiary' : 'bg-body-tertiary'}>
      <Container className={isHomePage ? 'homePageNavbar ' : ''}>
        <Navbar.Toggle className="navbarTogglerWhite" aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav.Link href="/">
            <div className="navbarLogoContainer">
              {GetImageUrls("/essentialPhotos/logoBlack.png", "logo navbarUsedLogo" , "Logo")}
            </div>
          </Nav.Link>
          <Nav className="me-auto navbarLinksContainer">
            <Nav.Link className="pageDirection" href="/gallery">GALERİ</Nav.Link>
            <Nav.Link className="pageDirection" href="/artists">SANATÇILAR</Nav.Link>
            <Nav.Link className="pageDirection" href="/blog">BLOG</Nav.Link>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}

export default MyNavbar;