export default function Filter(props){
  return (
    <select id={props.id} className="form-select form-select-lg">
      {props.options.map((option) => (
        <option key={option[0]} value={option[1]}>
          {option[2]}
        </option>
      ))}
    </select>
  );
}