import {useState, useRef} from "react";
import StudioLocation from "./StudioLocation";
import TheArtistGallery from "./TheArtistGallery";
import BookingDiv from "./BookingDiv";
import Button from 'react-bootstrap/Button';

function ContentSwitcher(props) {
  const [activeContent, setValue] = useState("content1");
  const elementRef = useRef(null);

  const handleClick = (contentId) => {
    window.scrollTo({ top:elementRef.current.offsetTop , behavior: "smooth" });
    setValue(contentId);
  };

  return (
    <>
      <div className="contentSwitcher">
        <div ref={elementRef} className="buttonGroup">
          <Button
            variant="dark"
            className={`${activeContent === "content1" ? "active" : ""}`}
            onClick={() => handleClick("content1")}
          >Galeri</Button>

          <Button
            variant="dark"
            className={`${activeContent === "content2" ? "active" : ""}`}
            onClick={() => handleClick("content2")}
          >Dükkan Konumu </Button>

          <Button
            variant="success"
            className={`${activeContent === "content3" ? "active" : ""}`}
            onClick={() => handleClick("content3")}
          >Randevu Ayarla</Button>
        </div>

        <div id="content-area">
          {activeContent === "content1" &&
            <div id="content1">
              <TheArtistGallery
                artist={props.artist}
              />
            </div>
          }
          {activeContent === "content2" &&
            <div id="content2">
              <StudioLocation
                artist={props.artist}
              />
            </div>
          }
          {activeContent === "content3" &&
            <div id="content3">
              <BookingDiv
                artist={props.artist}
              />
            </div>
          }
        </div>
      </div>
    </>
  );
}

export default ContentSwitcher;
