import { Image } from 'react-bootstrap';
import GetImageUrl from '../throughSiteComponents/GetImageUrl';

export default function TheArtistPhotoDiv(props){
  return (
    <div className="col-lg-3 col-md-4 col-sm-6 mainPhotoDiv">
      <div className="relativeDiv">
        <div className="photoPositionReference">
          <Image className="absolutePhoto skeleton" src={GetImageUrl(props.src)}/>
        </div>
      </div>
    </div>
  );
}