import {useState, useEffect} from "react";
import  Footer from "../throughSiteComponents/Footer";
import  BigPhotosWithPageDirections from "./BigPhotosWithPageDirections";
import  MyCarousel from "./MyCarousel";
import  SlidingImages from "./SlidingImages";

export default function DivThatSlidesOverWrapper(){

  const [isRight, setIsRight]=useState(window.innerWidth > 600);
  const [yMargin, setYMargin]=useState(0);

  useEffect(() => {
    const handleResize = () => {
      setIsRight(window.innerWidth > 600);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    const moveVertically = () => {
      setYMargin(window.scrollY*-0.5);
    };

    window.addEventListener('scroll', moveVertically);

    return () => {
      window.removeEventListener('scroll', moveVertically);
    };
  }, [window.scrollY]);

   return (
     <div className="divThatSlidesOverWrapper" style={{ top: `${yMargin}px` }}>
       <BigPhotosWithPageDirections
         class="photo0"
         buttonName= "Galeri"
         text="Dövmeniz hakkında fikir sahibi olmak için galerimize göz atın."
         src="/essentialPhotos/gallery.jpg"
         alt="Insect tattoo on arm photo."
         navigateTo="/gallery"
       />
       <BigPhotosWithPageDirections
         class="photo1"
         buttonName= "Sanatçılar"
         text="Sizin için en uygun sanatçıyı bulun."
         src= "/essentialPhotos/artists.jpg"
         alt="a women making a tattoo photo"
         rightSide={isRight}
         navigateTo="/artists"
       />
       <BigPhotosWithPageDirections
         class="photo2"
         buttonName= "Blog"
         text="Nereden başlayacağınızı bilmiyorsanız blogumuza göz atabilirsiniz."
         src="/essentialPhotos/blog.jpg"
         alt="running man with arm tattoos"
         navigateTo="/blog"
       />

       <MyCarousel />

       <SlidingImages />

       <Footer />

     </div>
   );
}