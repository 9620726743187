import TheArtistPhotoDiv from "./TheArtistPhotoDiv";

function TheArtistGallery(props){

  const photos=[
    {id:1, src:`/artists/kadikoy/${props.artist}/theArtistPhoto02.jpeg`},
    {id:2, src:`/artists/kadikoy/${props.artist}/theArtistPhoto03.jpeg`},
    {id:3, src:`/artists/kadikoy/${props.artist}/theArtistPhoto04.jpeg`},
    {id:4, src:`/artists/kadikoy/${props.artist}/theArtistPhoto05.jpeg`},
    {id:5, src:`/artists/kadikoy/${props.artist}/theArtistPhoto06.jpeg`},
    {id:6, src:`/artists/kadikoy/${props.artist}/theArtistPhoto07.jpeg`},
    {id:7, src:`/artists/kadikoy/${props.artist}/theArtistPhoto08.jpeg`},
    {id:8, src:`/artists/kadikoy/${props.artist}/theArtistPhoto09.jpeg`},
    {id:9, src:`/artists/kadikoy/${props.artist}/theArtistPhoto10.jpeg`},
    {id:10, src:`/artists/kadikoy/${props.artist}/theArtistPhoto11.jpeg`},
    {id:11, src:`/artists/kadikoy/${props.artist}/theArtistPhoto12.jpeg`},
    {id:12, src:`/artists/kadikoy/${props.artist}/theArtistPhoto13.jpeg`},
    {id:13, src:`/artists/kadikoy/${props.artist}/theArtistPhoto14.jpeg`}
  ]

  function createPhoto(photo){
    return (
      <TheArtistPhotoDiv
        key={photo.id}
        src={photo.src}
      />
    );
  }

  return (
    <div  className="theArtistPhotoDivsWrapper">
      <div className="row">
        {photos.map(createPhoto)}
      </div>
    </div>
  );
}

export default TheArtistGallery;