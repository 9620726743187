import React, {useState, useEffect, useRef} from "react";
import {useNavigate} from "react-router-dom";
import "../styles/Profile.css";
import { Button, Alert, Card } from 'react-bootstrap';
import { useAuth } from "../contexts/AuthContext"

import { collection, getDocs } from "firebase/firestore";
import { db } from "../config/tattooAppConfigure";

function Profile(){
  const [error, setError] = useState("")
  const { currentUser, logout } = useAuth()
  const navigate = useNavigate();

  const [works, setValue] = useState("");

  const totalPayment =useRef(0);
  const freePart =useRef(0);

  const getPreviousWorks = async () => {
    const email=currentUser.email;
    const querySnapshot1 = await getDocs(collection(db, "artistsAndEmails"));
    querySnapshot1.docs.forEach(async doc => {
      const data = doc.data();

      if (data.hasOwnProperty(email)) {
        const querySnapshot2 = await getDocs(collection(db, "ornekSanatcı", data[email], "previousWorks"));
        const previousWorks = querySnapshot2.docs.map(doc =>({id: doc.id, name:doc.data().name, date:doc.data().date, phone:doc.data().phone, customerExplanation:doc.data().customerExplanation, price:doc.data().price, discount:doc.data().discount, paidPart:doc.data().paidPart, recievedPayment:doc.data().recievedPayment }));
        setValue(previousWorks);
      }
    });
  }

  useEffect(() =>{
    getPreviousWorks();
  }, []);

  async function handleLogout(){
    setError("")

    try{
      await logout()
      navigate("/")
    }catch{
      setError("Oturum Sonlandırılamadı")
    }
  }


  function createCard(customer){
    totalPayment.current+=customer.paidPart;
    freePart.current+=1
    return (
      <Card className="profileInnerCard">
        <Card.Body>
          <p>{customer.name}</p>
          <Card.Text>
            <strong>Tarih:</strong> {customer.date}<br />
            <strong>Numara:</strong> {customer.phone}<br />
            <strong>Fiyat:</strong> {customer.price}<br />
            <strong>İndirim:</strong> {customer.discount}<br />
            <strong>Alınan Ödeme:</strong> {customer.recievedPayment}<br />
            <strong>Kesilen Komisyon:</strong> {customer.paidPart}<br />
            <strong>Açıklama:</strong> {customer.customerExplanation}<br />
          </Card.Text>
        </Card.Body>
      </Card>
    );
  }

  return (
    <div className="profileWrapper" >
      <Card className="profileCard">
        <Card.Body>
          <h2> Profil </h2>
          {error && <Alert variant="danger">{error}</Alert>}
          <strong> Email: </strong> {currentUser.email}
          <div>
            <Button variant="link" onClick={handleLogout}> Oturumu Sonlandır </Button>
          </div>
          {works && works.map(createCard)}


          <strong> Toplam Ödeme: </strong> {totalPayment.current} <br />
          <strong> Ücretsiz Kısım: </strong> {freePart.current} /10

        </Card.Body>
      </Card>

    </div>
  );
}

export default Profile;