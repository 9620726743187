import { useParams } from 'react-router-dom';
import "../styles/ThePost.css";
import  Footer from "../throughSiteComponents/Footer";
import NotToRegret from '../thePostPageComponents/NotToRegret';
import AfterCareOfTattoo from '../thePostPageComponents/AfterCareOfTattoo';

function ThePost(){
  const { post } = useParams();

  return (
    <>
      <div className="blogPostWrapper">
        {post==="NotToRegret" ? <NotToRegret /> : <AfterCareOfTattoo /> }
      </div>
      <Footer />
    </>
  );
}

export default ThePost;