import React, {useRef, useState,} from "react";
import "../styles/SignIn.css";
import {Link, useNavigate} from "react-router-dom"
import { Form, Button, Card, Alert } from 'react-bootstrap';
import { useAuth } from "../contexts/AuthContext";

function SignIn(){
  const emailRef = useRef();
  const passwordRef = useRef();
  const { login } = useAuth();
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate()

  async function handleSubmit(e){
    e.preventDefault()

    try{
      setError("")
      setLoading(true)
      await login(emailRef.current.value, passwordRef.current.value)
      navigate("/profile")
    }catch{
      setError("Giriş Esnasında Bir Hatayla Karşılaşıldı")
    }

    setLoading(false)
  }

  return (
    <div className="signInWrapper" >
      <Card className="signInCard">
        <Card.Body>
          <h2> GİRİŞ YAP </h2>
          {error && <Alert variant="danger">{error}</Alert>}
          <Form onSubmit={handleSubmit}>
            <Form.Group className="mb-3" controlId="formBasicEmail">
              <Form.Label>Email Adresiniz</Form.Label>
              <Form.Control type="email" ref={emailRef} required/>
            </Form.Group>

            <Form.Group className="mb-3" controlId="formBasicEmail">
              <Form.Label>Şifreniz</Form.Label>
              <Form.Control type="password" ref={passwordRef} required />
            </Form.Group>

            <Button disabled={loading} variant="dark" type="submit">
              Giriş Yap
            </Button>
          </Form>
        </Card.Body>
      </Card>
      <div>
        Şifrenizi Mi Unuttunuz? <Link to="/forgot-password"> Sıfırlayın </Link>
      </div>
    </div>
  );
}

export default SignIn;