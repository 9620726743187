import Image from 'react-bootstrap/Image';
import GetImageUrl from '../throughSiteComponents/GetImageUrl';

function NotToRegret(){

  return (
    <>
        <h2><strong>Dövme Yaptırma Rehberi</strong></h2>
        <p>
          Dövme, doğru şekilde ilerlenirse kusursuz sonuçlar verebilen bir deneyimdir.
          Ancak, istenilen şekilde ilerlemezse sonrasında pişmanlık yaşayabileceğiniz kalıcı bir ize de dönüşebilir.
          Bu süreci olabilecek en doğru şekilde yürütmek için dikkat etmeniz gereken bazı noktalar vardır:
        </p>
        <h3><strong>İyi Düşünün ve Araştırın</strong></h3>
        <p>
          Neden Yaptırmak İstediğinizi Anlayın: Dövme yaptırmak istemenizin sebebini iyi düşünün. Geçici bir heves mi, yoksa uzun süredir düşündüğünüz bir şey mi?
          Fikri belirledikten sonra belirli bir süre bekleyip (1, 2 ay) dövemeye bakış açınızın değişip değişmediğini kontrol edin.
        </p>
        <p>
          Tasarımı Seçin: Dövme tasarımınızı dikkatle seçin. Sizin için anlamlı bir dövme seçmek ileride pişman olma
          ihtimalinizi düşüreceğinden iyi bir başlangıç olabilir.
        </p>
        <Image className="thePostPhotos" src={GetImageUrl("postPhotos/notToRegret/notToRegret1.jpg")} />
        <h3><strong>Profesyonel Bir Dövme Sanatçısı Seçin</strong></h3>
        <p>
          Araştırma Yapın: Sanatçının daha önce yaptığı dövmeleri görmek için portföyünü inceleyin. İstediğiniz dövmenin ve tarzın sanatçının
          tarzıyla uyumlu olduğundan emin olun.
        </p>
        <h3><strong>Dövmenin Konumunu İyi Seçin</strong ></h3>
        <p>
          Görünürlük:Dövmenizin nerede olmasını istediğinize karar verin. Bazı insanlar dövmelerini sürekli görmek isterken, bazıları
          daha gizli bir yerde olmasını tercih eder.
        </p>
        <p>
          Ağrı Toleransı:Vücudun bazı bölgeleri diğerlerine göre daha acı verici olabilir. İlk dövmeniz için ağrı toleransınıza uygun bir
          bölge seçmek isteyebilirsiniz.
        </p>
        <Image className="thePostPhotos" src={GetImageUrl("postPhotos/notToRegret/notToRegret2.jpg")} />
        <h3><strong>Hazırlık ve Bakım</strong></h3>
        <p>
          Sağlık Durumu: Dövme yaptırmadan önce genel sağlık durumunuzun iyi olduğundan emin olun. Kan pıhtılaşma bozukluğu veya cilt problemleriniz
          varsa doktorunuza danışın. Öncesinde dinlenmiş ve iyi beslenmiş olduğunuzdan emin olun.
        </p>
        <p>
          Bakım Talimatları: Dövmenizin iyileşme sürecinde sanatçının verdiği bakım talimatlarına uymanız çok önemlidir. Dövmenizi temiz tutun ve nemlendirin.
          Direkt güneş ışığından ve enfeksiyon riski taşıyan ortamlardan kaçının.
        </p>
        <h3><strong>Sabırlı Olun</strong></h3>
        <p>
           Beklentilerinizi Yönetmek: Dövmenin iyileşmesi zaman alacaktır. İlk başta biraz kızarıklık ve kabuklanma normaldir. Tamamen iyileşmesi için sabırlı olun.
        </p>
        <Image className="thePostPhotos" src={GetImageUrl("postPhotos/notToRegret/notToRegret3.jpg")} />
    </>
  );
}

export default NotToRegret;