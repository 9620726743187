import {useState, useEffect} from "react";
import { Image, Button } from 'react-bootstrap';
import GetImageUrl from '../throughSiteComponents/GetImageUrl';
import { doc, getDoc } from "firebase/firestore";
import { db } from "../config/tattooAppConfigure";

function StudioLocation(props){

  const [location, setValue]=useState([{id:1, name:"example"}]);

  const getLocation = async () => {
    const docRef = doc(db, "artists", props.artist);
    const docSnap = await getDoc(docRef);
    const location = docSnap.data().location
    setValue(location);
  }

  useEffect(() =>{
    getLocation();
  }, []);

  return (
    <div className="studioLocation">
      <div className="locationPhotoDiv skeleton">
        <Image className="locationPhoto" src={GetImageUrl(`/artists/kadikoy/${props.artist}/studioLocation.jpg`)}/>
      </div>
      <Button className="locationButton" variant="dark" href={location} target="_blank" rel="noopener noreferrer">
        Google Maps Kullanarak Aç
      </Button>
    </div>
  );
}

export default StudioLocation;