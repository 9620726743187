import "../styles/Blog.css";
import  Footer from "../throughSiteComponents/Footer";
import Post from "../blogPageComponents/Post";

function Blog(){
  return (
    <div className="blogWrapper">
      <div className="blog">
        <Post
          leftSide={true}
          src="/essentialPhotos/blog1.jpg"
          text="Dövmenizden Pişman Olmamak İçin Dikkat Etmeniz Gerekenler"
          navigateTo="/thePost/NotToRegret"
          className="postPhoto1"
        />
        <Post
          leftSide={false}
          src="/essentialPhotos/blog1.jpg"
          text="Dövme İşleminiz Sonrasında Bakım Önerileri"
          navigateTo="/thePost/AfterCareOfTattoo"
          className="postPhoto2"
        />
      </div>
      <Footer />
    </div>
  );
}

export default Blog;