import React from "react";
import { BrowserRouter, Routes, Route} from "react-router-dom";
import { Helmet } from 'react-helmet';
import GetImageUrl from './throughSiteComponents/GetImageUrl';
import Navbar from "./throughSiteComponents/Navbar";
import { AuthProvider } from "./contexts/AuthContext"
import PrivateRoute from "./PrivateRoute";

import Home from "./pages/Home";
import Artists from "./pages/Artists";
import Gallery from "./pages/Gallery";
import TheArtist from "./pages/TheArtist";
import Blog from "./pages/Blog";
import SignIn from "./pages/SignIn";
import ForgotPassword from "./pages/ForgotPassword";
import Profile from "./pages/Profile";
import ThePost from "./pages/ThePost";
import NewArtistOrLogin from "./pages/NewArtistOrLogin";
import AreYouAnArtist from "./pages/AreYouAnArtist";

function App(){
  return (
    <BrowserRouter>

      <Helmet>
        <link rel="icon" type="image/png" href={GetImageUrl("/essentialPhotos/logoBlack.png")} />
      </Helmet>

      <Navbar />
      {/* Footer ı buraya koyamazsın çünkü home da zindexi 2 olan bir yerde durması gerekicek. */}

      <AuthProvider>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/ourNewArtist" element={<AreYouAnArtist />} />
          <Route path="/artists" element={<Artists />} />
          <Route path="/theArtist/:artist" element={<TheArtist />}/>
          <Route path="/gallery" element={<Gallery />} />
          <Route path="/blog" element={<Blog />} />
          <Route path="/newArtistOrLogIn" element={<NewArtistOrLogin />} />
          <Route path="/signin" element={<SignIn />} />
          <Route path="/forgot-password" element={<ForgotPassword />} />
          <Route element={<PrivateRoute />} >
            <Route path="/profile" element={<Profile />} />
          </Route>
          <Route path="/thePost/:post" element={<ThePost />} />
        </Routes>
      </AuthProvider>

    </BrowserRouter>
  );
}

export default App;