import {useState, useEffect} from "react";
import { Link } from "react-router-dom";
import ArtistCard from "./ArtistCard";
import OverlayCard from "./OverlayCard";
import { Button } from 'react-bootstrap'

export default function Card(props){

  const [showOverlay, setValue] =useState(false)

  useEffect(()=>{
    setValue(props.overlay)
  }, [props.overlay])

  return (
    <div className="col-lg-3 col-md-4 col-sm-6 artistCardWrapper">
    <div className="card">
      {showOverlay ?
        <>
          <Button className="btn-close" size="lg" onClick={()=>setValue(!showOverlay)}/>
          <Link to={`/theArtist/${props.name}`}>
            <Button variant="dark" className="bookingButton bookingButtonSecond">
              Randdevu Ayarla
            </Button>
          </Link>
          <OverlayCard
            artist={props.name}
          />
        </>
        :
        <>
          <Button variant="dark" size="lg" className="topRightButton" onClick={()=>setValue(!showOverlay)} >
            GALERİ
          </Button>
          <ArtistCard
             artist={props.name}
             location={props.location}
          />
        </>
      }
    </div>
    </div>
  );
}