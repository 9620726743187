import "./throughSiteComponentsStyle.css";
import Image from 'react-bootstrap/Image';
import GetImageUrl from './GetImageUrl';


function Footer(props){
   return (
     <footer className="footer" style={{ backgroundColor: `${props.color}` }}>
       <div className="footer-container">
         <div className="upperFooter">
           <div className="logoContainer">
              <Image className="logo" alt="logo" src={GetImageUrl("/essentialPhotos/logoWhite.png")}/>
           </div>
           <div className="iconContainer">
            <div className="iconContainerInsideDiv">
             <p className="slogan"> Your tattoo artist in Istanbul </p>
             <a href="https://www.instagram.com/tattooistcompany/"> <Image className="logo" src={GetImageUrl("/essentialPhotos/instagramIcon.png")}/> </a>
            < /div>
           </div>
         </div>
         <p> tattoodoer@gmail.com </p>
         <p className="copyright"> 2024 Website. All rights reserved. </p>
       </div>
     </footer>
   );
}

export default Footer;