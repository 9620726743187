import { Link } from "react-router-dom";
import { Image, Button } from 'react-bootstrap';
import GetImageUrl from '../../throughSiteComponents/GetImageUrl';

export default function ArtistCard(props){
  return (
    <>
      <Image className="card-img-top rounded-circle skeleton" src={GetImageUrl(`/artists/kadikoy/${props.artist}/header.jpeg`)}/>
      <div className="card-body">
        <h1 className="card-title">{props.artist}</h1>
        <p className="locationInfo">{props.location}</p>
        <Link to={`/theArtist/${props.artist}`}>
          <Button variant="dark" className="bookingButton bookingButtonFirst">
            Randdevu Ayarla
          </Button>
        </Link>
      </div>
    </>
  );
}