import { useRef, useEffect } from 'react';
import Image from 'react-bootstrap/Image';
import { getDownloadURL, ref } from 'firebase/storage';
import { storage } from '../config/tattooAppConfigure';

function GetImageUrls( path, className, alt, style ) {
  //Her seferinde ayrı bir re rendering olmasın diye bunu state yerine ref yaptım.
  const image = useRef();

  // asenkron çalışıyo doğru bir şekilde storage dan çekiyo sıkıntı yok.
  // const ve function olarak adlandırmanın bi farkı yok aynı fonksiyon içinde kullanıcaksan const farklısındaysa function diyebilirsin.
  const fetchImage = async () => {
    const imageRef = ref(storage, path);
    const url = await getDownloadURL(imageRef);
    image.current= url;
  };

  useEffect(() => {
    fetchImage();
  }, []);

  return (
    <>
    {image.current && <Image src={image.current} className={className} style={style} alt={alt} />}
    </>
  );
}

export default GetImageUrls;
