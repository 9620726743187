import "../styles/Artists.css";
import  ArtistsWrapper from "../artistsPageComponents/ArtistsWrapper";
import  Footer from "../throughSiteComponents/Footer";

function Artists(){
  return (
    <>
      <ArtistsWrapper />
      <Footer />
    </>
  );
}

export default Artists;